import React from "react";
import { 
  Building2, Trees, Users, PocketIcon as Pool, PlayCircle, Theater, 
  Shield, SpaceIcon as Yoga, WallpaperIcon as Wall, Building, Store, 
  ShoppingCart, PlugIcon as Pipeline 
} from "lucide-react";
import './Amenities.css'
 
const amenities = [
  {
    icon: <Building2 className="h-6 w-6 text-dark" />,
    title: "Secure",
    description: "Gated Community",
  },
  {
    icon: <Trees className="h-6 w-6 text-dark" />,
    title: "Landscaped",
    description: "Gardens",
  },
  {
    icon: <Users className="h-6 w-6 text-dark" />,
    title: "Multi-Purpose",
    description: "Clubhouse",
  },
 
  {
    icon: <PlayCircle className="h-6 w-6 text-dark" />,
    title: " Fun Space",
    description: "Children Park",
  },
  {
    icon: <Theater className="h-6 w-6 text-dark" />,
    title: "Open Gatherings",
    description: "Amphitheater",
  },
  {
    icon: <Shield className="h-6 w-6 text-dark" />,
    title: "Residents",
    description: "24/7 Security",
  },
  {
    icon: <Yoga className="h-6 w-6 text-dark" />,
    title: " Relaxation Zone",
    description: " Yoga Zone",
  },
  {
    icon: <Wall className="h-6 w-6 text-dark" />,
    title: "Strong ",
    description: "Compound Wall",
  },
  {
    icon: <Building className="h-6 w-6 text-dark" />,
    title: "Majestic Entrance",
    description: "Entrance Arch",
  },
  {
    icon: <Store className="h-6 w-6 text-dark" />,
    title: "Well Stocked",
    description: "Super market",
  },
  {
    icon: <ShoppingCart className="h-6 w-6 text-dark" />,
    title: " One-Stop",
    description: "Grocery Store",
  },
  {
    icon: <Pipeline className="h-6 w-6 text-dark" />,
    title: "Drainage",
    description: "ModUnderground",
  },
  {
    icon: <Building2 className="h-6 w-6 text-dark" />,
    title: "Secure",
    description: "Gated Community",
  },
  {
    icon: <Trees className="h-6 w-6 text-dark" />,
    title: "Landscaped",
    description: "Gardens",
  },
  {
    icon: <Users className="h-6 w-6 text-dark" />,
    title: "Multi-Purpose",
    description: "Clubhouse",
  },
 
  {
    icon: <PlayCircle className="h-6 w-6 text-dark" />,
    title: " Fun Space",
    description: "Children Park",
  },
  {
    icon: <Theater className="h-6 w-6 text-dark" />,
    title: "Open Gatherings",
    description: "Amphitheater",
  },
  {
    icon: <Shield className="h-6 w-6 text-dark" />,
    title: "Residents",
    description: "24/7 Security",
  },
  {
    icon: <Yoga className="h-6 w-6 text-dark" />,
    title: " Relaxation Zone",
    description: " Yoga Zone",
  },
  {
    icon: <Wall className="h-6 w-6 text-dark" />,
    title: "Strong ",
    description: "Compound Wall",
  },
  {
    icon: <Building className="h-6 w-6 text-dark" />,
    title: "Majestic Entrance",
    description: "Entrance Arch",
  },
  {
    icon: <Store className="h-6 w-6 text-dark" />,
    title: "Well Stocked",
    description: "Super market",
  },
  {
    icon: <ShoppingCart className="h-6 w-6 text-dark" />,
    title: " One-Stop",
    description: "Grocery Store",
  },
  {
    icon: <Pipeline className="h-6 w-6 text-dark" />,
    title: "Drainage",
    description: "ModUnderground",
  },
  {
    icon: <Building2 className="h-6 w-6 text-dark" />,
    title: "Secure",
    description: "Gated Community",
  },
  {
    icon: <Trees className="h-6 w-6 text-dark" />,
    title: "Landscaped",
    description: "Gardens",
  },
  {
    icon: <Users className="h-6 w-6 text-dark" />,
    title: "Multi-Purpose",
    description: "Clubhouse",
  },
 
  {
    icon: <PlayCircle className="h-6 w-6 text-dark" />,
    title: " Fun Space",
    description: "Children Park",
  },
  {
    icon: <Theater className="h-6 w-6 text-dark" />,
    title: "Open Gatherings",
    description: "Amphitheater",
  },
  {
    icon: <Shield className="h-6 w-6 text-dark" />,
    title: "Residents",
    description: "24/7 Security",
  },
  {
    icon: <Yoga className="h-6 w-6 text-dark" />,
    title: " Relaxation Zone",
    description: " Yoga Zone",
  },
  {
    icon: <Wall className="h-6 w-6 text-dark" />,
    title: "Strong ",
    description: "Compound Wall",
  },
  {
    icon: <Building className="h-6 w-6 text-dark" />,
    title: "Majestic Entrance",
    description: "Entrance Arch",
  },
  {
    icon: <Store className="h-6 w-6 text-dark" />,
    title: "Well Stocked",
    description: "Super market",
  },
  {
    icon: <ShoppingCart className="h-6 w-6 text-dark" />,
    title: " One-Stop",
    description: "Grocery Store",
  },
  {
    icon: <Pipeline className="h-6 w-6 text-dark" />,
    title: "Drainage",
    description: "ModUnderground",
  },
];
function AmenityCard({ icon, title, description,index }) {
  return (
    <div className="card border-none   bg-yellow">
      <div className="card-body p-0 d-flex gap-3">
        <div className="rounded bg-white  bg-opacity-10 p-3 d-flex align-items-center justify-content-center">
        {amenities[index].icon} 
        </div>
        <div>
          <p className=" mb-1">{title}</p>
          <h5 className="card-text text-dark mb-0">{description}</h5>
        </div>
      </div>
    </div>
  );
}

export default function Amenities({data}) {
 

  return (
    <div className="bg-light-main mt-5 py-107">
      <div className="container">
        <div className="text-left mb-5">
          <h2 className="fw-bold text-dark">Amenities & Utilities</h2>
          
        </div>
        <div className="row g-4">
          {data?.AmenitySchema.map((amenity, index) => (
            <div key={index} className="  custom-col ">
              <AmenityCard {...amenity}  index={index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
