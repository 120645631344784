import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import './BuilderDetails.css';
import './theSky.css';
import { getAdvertisement } from "../api/api";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoClose } from "react-icons/io5";
import Fade from 'react-reveal/Fade';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import highlight from "../assets/highlight.png";
import highlight_2 from "../assets/highlight_2.png";
import location from "../assets/location.png";
import image_1 from "../assets/image_1.png";
import image_2 from "../assets/image_2.png";
import image_3 from "../assets/image_3.png";
import sanitary from "../assets/sanitary.png";
import layout_1 from "../assets/layout_1.png";
import layout_2 from "../assets/layout_2.png";
import layout_3 from "../assets/layout_3.png";
import layout_4 from "../assets/layout_4.png";
import layout_5 from "../assets/layout_5.png";
import layout_6 from "../assets/layout_6.png";
import layout_7 from "../assets/layout_7.png";
import windows from "../assets/windows.png";
import door from "../assets/door.png";
import lift from "../assets/lift.png";
import snapshot_1 from "../assets/snapshot_1.jpg";
import snapshot_2 from "../assets/snapshot_2.jpg";
import snapshot_3 from "../assets/snapshot_3.jpg";
import snapshot_4 from "../assets/snapshot_4.jpg";
import snapshot_5 from "../assets/snapshot_5.jpg";
import snapshot_6 from "../assets/snapshot_6.jpg";
import snapshot_7 from "../assets/snapshot_7.jpg";

import '../assets/assets/vendor/animate.css/animate.min.css'
import '../assets/assets/vendor/aos/aos.css'
import '../assets/assets/vendor/bootstrap/css/bootstrap.min.css'
import '../assets/assets/vendor/bootstrap-icons/bootstrap-icons.css'
import '../assets/assets/vendor/boxicons/css/boxicons.min.css'
import '../assets/assets/vendor/glightbox/css/glightbox.min.css'
import '../assets/assets/vendor/remixicon/remixicon.css'
import '../assets/assets/vendor/swiper/swiper-bundle.min.css'
import '../assets/assets/css/style1.css'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    }
  };

export default function TheSky(props) {
    const { companyname, files, message, completed_projects, project_progress, priceDetail1, priceDetail2, address} = props.BuilderDetail || {};
    let Features = Array.from(JSON.parse(props.BuilderDetail.Features)) || {};
    let completedProjectsData = Array.from(JSON.parse(props.BuilderDetail.completed_projects)) || {};
    let projectProgressData = Array.from(JSON.parse(props.BuilderDetail.project_progress)) || {};
    let Highlights = Array.from(JSON.parse(props.BuilderDetail.Highlights)) || {};
    let layoutdetails = Array.from(JSON.parse(props.BuilderDetail.layoutdetails)) || {};
    let photosOfBuilder = Array.from(JSON.parse(props.BuilderDetail.photosOfBuilder)) || {};
    const [state, setState] = useState({});
    const [sideBar, setSideBar] = useState(false);
    const [dropdownView, setDropDown] = useState(false);
    const builderSubdomainProps = props.builderSubdomain || null;
    const [builderAds, setbuilderAds] = useState([]);
    const [completed_projectsArr, setcompleted_projectsArr] = useState(completed_projects);
    const [project_progressArr, setproject_progressArr] = useState(project_progress);
    const handleClick = () => {
        setSideBar(s => !s);
    };
    useEffect(() => {
        getAdvertisement().then((builderAdsVal) => {
            const advertData = builderAdsVal.data || [];
            const filteredBuilderAds = _.filter(advertData, function (ad) {
                try {
                    if (typeof ad.builder === 'object' && ad.builder[0] !== undefined) {
                        let ads_datadd = JSON.parse(ad.builder);
                        if (ads_datadd) {
                            let loopSubdomain = ads_datadd[0].subdomain;
                            return loopSubdomain === builderSubdomainProps;
                        }
                    }
                } catch (e) {
                    //console.warn(e)
                }
            });
            setbuilderAds(filteredBuilderAds);
        });
    }, [builderSubdomainProps]);
    const file_path = 'https://backend.myplotpic.com/uploads/';
    const builder_image = (files ? file_path + files[0] : '');
    useEffect(() => {
        if (project_progress !== undefined) {
            setproject_progressArr(JSON.parse(project_progress));
        }
        if (completed_projects !== undefined) {
            setcompleted_projectsArr(JSON.parse(completed_projects));
        }
        return () => {
            setState({}); // Unmounting to prevent Memory Leak
        }
    }, [completed_projects, project_progress]);
    const [datetime, setTime] = useState(new Date());
    useEffect(() => {
        setInterval(() => {
            setTime(new Date());
        }, 1000)
    }, [datetime])
    return (
        <div>
            <Modal
                show={sideBar}
                backdrop="static"
                keyboard={false}
                dialogClassName="border-radius-1"
                centered
                size="sm"
                fullscreen={true}
                >
                <ModalBody>
                    <IoClose
                        size={30}
                        className="new_closeIconX"
                        onClick={handleClick}
                    />
                    <div className='new_sideBar-mobile'>
                        <ul>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto active" href="#overview">Overview</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#aminities">Aminities</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#highlights">Highlights</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto " href="#project">Project Details</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#approvals">Approvals</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#pricing">Pricing</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#specifications">Specifications</a>
                            </li>
                        </ul>
                    </div>
                </ModalBody>
            </Modal>
            <div class="new_navbar">
                <Row>
                    <Col md={3} xs={10} sm={10}>
                        <img src={builder_image} style={{ height: 50, width: 164 }}  alt='logo' />
                    </Col>
                    <Col md={9} xs={2} sm={2}>
                        <nav id="navbar" className="navbar alignRightItems">
                            <ul>
                                <li onMouseOver={() => setDropDown(false)}><a className="nav-link scrollto" href="#overview">Overview</a></li>
                                <li onMouseOver={() => setDropDown(false)}><a className="nav-link scrollto" href="#aminities">Aminities</a></li>
                                <li onMouseOver={() => setDropDown(true)}><a className="nav-link scrollto" href="#project">Project Details</a></li>
                                <li onMouseOver={() => setDropDown(false)}><a className="nav-link scrollto" href="#approvals">Approvals</a></li>
                                <li onMouseOver={() => setDropDown(false)}><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
                                <li onMouseOver={() => setDropDown(false)}><a className="nav-link scrollto" href="#specifications">Specifications</a></li>
                            </ul>
                            <i className="bi bi-list mobile-nav-toggle" onClick={handleClick}></i>
                            {
                            dropdownView?
                            <div className="categories-hover-div" onMouseOver={() => setDropDown(true)}>
                                <center>
                                    <a
                                        href="#snapshots"
                                        className="nav-link_new scrollto"
                                        style={{ height: "33%"}}
                                        onMouseOver={() => setDropDown(true)}
                                    >
                                        Snapshots
                                    </a>
                                    <a
                                        href="#location"
                                        className="nav-link_new scrollto"
                                        style={{ height: "33%"}}
                                        onMouseOver={() => setDropDown(true)}
                                    >
                                        Location
                                    </a>
                                    <a
                                        href="#progress"
                                        className="nav-link_new scrollto"
                                        style={{ height: "33%"}}
                                        onMouseOver={() => setDropDown(true)}
                                        onMouseOut={() => setDropDown(false)}
                                    >
                                        Progress
                                    </a>
                                </center>
                            </div>
                            :
                            <></>
                        }
                        </nav>
                        
                    </Col>
                </Row>
            </div>
            <div className='new_UI_landing' id="overview">
                <div className='new_UI_landing_inner'>
                    <Row>
                        <Col md={5} xs={11} sm={11}>
                            <p className='new_UI_landing_inner_txt'>
                                V9 MSN The sky project developing by Vishwanadh Avenues one of the leading construction companies in Vizag. Located in Seetammadhara and leads to the one of the iconic project in entire vizag.
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='new_UI_landing_2' id="aminities">
                <div className='new_UI_landing_inner_2 inner_2_margins'>
                    <Fade bottom duration={1250}>
                        <Row>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Infinity Sky bar and Lounge</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Go Beyond TheSky Open Gym</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Entrance Lounge</p>
                                    </div>
                                </center>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Viewing Deck</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Mindfull Yoga Deck</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Buddha Garden</p>
                                    </div>
                                </center>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Green Terraces</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Little Hawaii Swimming Pool</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Kid’s Play Arena</p>
                                    </div>
                                </center>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Matrix Zen Gardens</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Changing Rooms</p>
                                    </div>
                                </center>
                            </Col>
                            <Col md={4} xs={4} sm={4}>
                                <center>
                                    <div className='new_UI_landing_2_card'>
                                        <p className='inner_2_txt'>Platform 9¾ Recreational Zone</p>
                                    </div>
                                </center>
                            </Col>
                        </Row>
                    </Fade>
                </div>
            </div>
            <br/>
            <div className='new_UI_highlights' id="project">
                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <p className='new_UI_highlights_title'>V9 MSN The sky project is prestigous and iconic project </p>
                        <p className='new_UI_highlights_txt'>
                            one among the Vizag. Located in Seetammadhara heart of the city.
                            Surrounded by beautiful hill. V9 MSN The sky consists 10 Floor residential Apartment. Offers 3BHK with size of 2075 to 2225 SFT.
                        </p>
                        <p className='new_UI_highlights_txt'>
                            Consists of ameneties like Infinity SKy bar and Lounge, Viwing Deck, Green Terraces, Matrix Zen Gardens, GO Beyond the Sky open gym,
                            Mindfull Yoga Deck, Little Hawaii Swimmimg Pool, Changing Rooms, Entrance Lounge, Budha Garden, Kids Play Arena,
                            Platform 93/4 Recreational Zone 
                        </p>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                        <div className='new_UI_highlights_right'>
                            <img src={highlight} className="highlight_img"/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='new_UI_highlights'>
                <p className='new_UI_highlights_title'>Floor Plan</p>
                <Carousel
                    autoPlay={true}
                    autoPlaySpeed={1600}
                    infinite={true}
                    swipeable={true}
                    itemClass="image-item"
                    responsive={responsive}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                    <img src={layout_1} className='floorplan_img'/>
                    <img src={layout_2} className='floorplan_img'/>
                    <img src={layout_3} className='floorplan_img'/>
                    <img src={layout_4} className='floorplan_img'/>
                    <img src={layout_5} className='floorplan_img'/>
                    <img src={layout_6} className='floorplan_img'/>
                    <img src={layout_7} className='floorplan_img'/>
                </Carousel>
            </div>
            <div className='new_UI_highlights'>
                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <p className='new_UI_highlights_title'>Locality</p>
                        <p className='new_UI_highlights_txt'>
                            Seetamadhara is one of the prime area of the VIZAG. Connected to NH16 ,Port Indoor Stadium, Auditorium,Cricket Stadium, Fitness Gyms, Governament Organizations,Shopping Malls, Vegitable Markets, VIZAG Central Busstation, Railway Station, Schools , Colleges and Hospitals.
                        </p>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                        <div>
                            <img src={highlight_2} style={{height: 320, width: "100%", marginTop: "5%"}}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='new_UI_highlights' id="snapshots">
                <p className='new_UI_highlights_title'>Snapshots</p>
                <Carousel
                    autoPlay={true}
                    autoPlaySpeed={1600}
                    infinite={true}
                    swipeable={true}
                    showDots={true}
                    itemClass="image-item"
                    responsive={responsive}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                    <img src={snapshot_1} className='snapshot_img'/>
                    <img src={snapshot_2} className='snapshot_img'/>
                    <img src={snapshot_3} className='snapshot_img'/>
                    <img src={snapshot_4} className='snapshot_img'/>
                    <img src={snapshot_5} className='snapshot_img'/>
                    <img src={snapshot_6} className='snapshot_img'/>
                    <img src={snapshot_7} className='snapshot_img'/>
                </Carousel>
            </div>
            <div className='new_UI_highlights' id="location">
                <p className='new_UI_highlights_title'>Location</p>
                <div className='location-bg-div'>
                    <Row>
                        <Col md={6}>
                            <center>
                                <button
                                    className="visit-site"
                                >
                                    Get Directions
                                </button>
                            </center>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                </div>
                <div className='location-bg-div-mobile'>
                    <img src={location} className="location_img"/>
                    <center>
                        <button
                            className="visit-site"
                        >
                            Get Directions
                        </button>
                    </center>
                </div>
            </div>
            <div className='new_UI_highlights' id="progress">
                <p className='new_UI_highlights_title'>Construction Progress</p>
                <div className="iframe_shadow">
                    <iframe src={"https://www.youtube.com/embed/IMPbKVb8y8s"} allowfullscreen width="1265" height="440" className="new_UI_iframe" style={{borderRadius:10}}></iframe>
                </div>
                <iframe src={"https://www.youtube.com/embed/IMPbKVb8y8s"} allowfullscreen width="300" height="200" className="new_UI_iframe_mobile" style={{borderRadius: 10}}></iframe>
            </div>
            <section id="pricing" className="pricing">
                <p className='new_UI_highlights_title marginLefts'>Pricing</p>
                <div className="container">
                    <Fade bottom duration={1250}>
                        <div className="row justify-content-md-center">
                            <div className="col-lg-3 col-md-6">
                                <div className="box featured">
                                    <h3>Price Details</h3>
                                    <p>Rs. 24,000/square yard</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
                                <div className="box featured">
                                    <h3>Current Market Details</h3>
                                    <p>Rs. 32,000/square yard</p>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <div className='new_UI_highlights' id="approvals">
                <p className='new_UI_highlights_title'>Approvals</p>
                <Row className='logos_margin'>
                    <Col md={4} sm={4} xs={4}>
                        <center>
                            <img src={image_1} style={{width: 200, marginTop: "1%"}}/>
                        </center>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <center>
                            <img src={image_2} style={{width: 200, marginTop: "1%"}}/>
                        </center>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <center>
                            <img src={image_3} style={{width: 130, marginTop: "1%"}}/>
                        </center>
                    </Col>
                </Row>
            </div>
            <div className='new_UI_highlights' id="specifications">
                <p className='new_UI_highlights_title'>Specifications</p><br/>
                <Fade bottom duration={1250}>
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <div className='specification_card'>
                                <Row>
                                    <Col md={5} xs={6} sm={6}>
                                        <img src={sanitary} className="specification_img"/>
                                    </Col>
                                    <Col md={7} xs={6} sm={6}>
                                        <p className='specification_title'>Toilets</p>
                                        <p className='specification_txt'>
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={6} sm={12} xs={12}>
                            <div className='specification_card'>
                                <Row>
                                    <Col md={5} xs={6} sm={6}>
                                        <img src={windows} className="specification_img"/>
                                    </Col>
                                    <Col md={7} xs={6} sm={6}>
                                        <p className='specification_title'>Windows</p>
                                        <p className='specification_txt'>
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <div className='specification_card'>
                                <Row>
                                    <Col md={5} xs={6} sm={6}>
                                        <img src={door} className="specification_img"/>
                                    </Col>
                                    <Col md={7} xs={6} sm={6}>
                                        <p className='specification_title'>Doors</p>
                                        <p className='specification_txt'>
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={6} sm={12} xs={12}>
                            <div className='specification_card'>
                                <Row>
                                    <Col md={5} xs={6} sm={6}>
                                        <img src={lift} className="specification_img"/>
                                    </Col>
                                    <Col md={7} xs={6} sm={6}>
                                        <p className='specification_title'>Lift</p>
                                        <p className='specification_txt'>
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Fade>
            </div>
            <section id="cta" className="cta">
                <div className="container">
                    <p className='progress-txt-builder-white'>Call to action</p>
                    <Fade bottom duration={1250}>
                        <div className="row">
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect?name="+companyname +"&type=Buy a Plot&message=I would like to enquire reqarding buying a plot .."}>Buy a Plot</a>
                            </div>
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect?name="+companyname +"&type=Order Virtual Tour&message=I would like to enquire reqarding virtual tour of my property .."}>Order Virtual tour</a>
                            </div>
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect?name="+companyname +"&type=Order Physical Visit&message=I would like to enquire reqarding physical visit of my property at .."}>Order Physical visit</a>
                            </div>
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect?name="+companyname +"&type=Resale a Plot&message=I would like to enquire reqarding reselling my property located at .."}>Resale a plot</a>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
        </div>
    )
}
