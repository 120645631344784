import _ from 'lodash';

const getBuilders = async (builder_subdomain) => {
  console.log("builder_subdomain", builder_subdomain);
  const request = await fetch(`https://backend.myplotpic.com/api/getBuilder`);
  const Builders = await request.json();
  const BuildersData = await Builders.data;

  console.log("BuildersData", BuildersData);

  // Filter using optional chaining to prevent errors on undefined subdomain
  const builderfil = BuildersData.filter(
    (builder) =>
      builder.subdomain?.toLowerCase() === builder_subdomain.toLowerCase()
  );

  if (builderfil.length === 0) {
    console.error("No matching builder found!");
    return null;
  }

  console.log("Filtered Builder:", builderfil);

  // Assuming you have a `getBuilderById` function
  const res = await getBuilderById(builderfil[0]._id);

  return res.data;
};



export const getBuilderById = async (id) => {
    try {
      const response = await fetch(`https://backend.myplotpic.com/api/getbuilder/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data = await response.json(); // Parse the JSON response
      return data;
    } catch (error) {
      console.error('Error fetching builder by ID:', error);
      throw error;
    }
  };

const getAdvertisement = async () => {
    const request = await fetch(`https://backend.myplotpic.com/api/getAdvertisement`);
    const Advertisement = await request.json();
    return Advertisement;
}



export { getBuilders, getAdvertisement }