import React from 'react'
import './project.css'

const ProjectsFeatures = ({data}) => {
  return (
    <div>
        
        <div class="min-vh-100 bg-gradient p-4">
  <div class="container">
    <div class="text-center text-white mb-4 upper-div">
      <h2 class="fw-semibold">Projects Features</h2>
      <p class="fs-5">Total Extent: 2500 Acres</p>
    </div>

    <div class="row g-5">
      
    {data?.Features.map((i,key)=>(

      <div key={key} class="col-12 col-md-6 col-lg-4 px-0">
        <div className='px-3'>

       
        <div class="card bg-transparent backdrop-blur mx-auto rounded-4 p-4">
          <div class="mb-3 d-flex justify-content-between align-items-center inner-div">
            <h3 class=" fs-5 fw-medium">{i?.title}</h3>
            <span class="">{i?.percentvalue}</span>
          </div>
          <div class="row g-3">
            {i?.detailfeatures.map((item,keyindex)=>(<div class="col-6" key={keyindex}>
              <div class="bg-info inner-sub-div bg-opacity-50 inner-sub-div rounded-3 p-3 text-center">
                <div class="fs-4 fw-bold text-dark">{item?.value}</div>
                <div class="small text-muted">{item?.subtitle}</div>
                <div class="small text-secondary mt-1">{item?.unit_value}</div>
              </div>
            </div>))}
             
          </div>
        </div>
        </div>
      </div> ))}

      
      
    </div>
  </div>
</div>


    </div>
  )
}

export default ProjectsFeatures