

import React, { useEffect, useRef, useState } from 'react';

const loadGoogleMapsScript = () => {
  return new Promise((resolve, reject) => {
    if (window.google) {
      return resolve(window.google);
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBttNovQQfTde-VkUHOugPWUrgr9DkmmaU&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => resolve(window.google);
    script.onerror = () => reject('Google Maps script could not be loaded');
  });
};

const MapComponent = ({ data }) => {
  const mapContainerRef = useRef(null);  // Reference to the map container
  const mapRef = useRef(null);           // Reference to the Google Map instance
  const markerRef = useRef(null);        // Reference to the Marker instance

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    loadGoogleMapsScript()
      .then(() => {
        setIsMapLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (isMapLoaded && mapContainerRef.current) {
      const initialLat = parseFloat(data?.lat+10) || 12.345678;  // Fallback to default if invalid
      const initialLng = parseFloat(data?.lng+10) || 98.765432; // Fallback to default if invalid

      console.log("Initial lat:", initialLat);
      console.log("Initial lng:", initialLng);

      // Validate the lat and lng values
      if (!isNaN(initialLat) && !isNaN(initialLng)) {
        mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
          center: { lat: initialLat, lng: initialLng },
          zoom: 15,
        });

        markerRef.current = new window.google.maps.Marker({
          position: { lat: initialLat, lng: initialLng },
          map: mapRef.current,
        });
      } else {
        console.error('Invalid lat or lng values:', data?.lat, data?.lng);
      }
    }
  }, [isMapLoaded, data]);

  useEffect(() => {
    if (isMapLoaded && mapRef.current && markerRef.current) {
      const newPosition = { lat: parseFloat(data?.lat), lng: parseFloat(data?.lng) };

      console.log("New lat:", newPosition.lat);
      console.log("New lng:", newPosition.lng);

      // Validate the new position
      if (!isNaN(newPosition.lat) && !isNaN(newPosition.lng)) {
        mapRef.current.setCenter(newPosition);
        markerRef.current.setPosition(newPosition);
      } else {
        console.error('Invalid lat or lng values:', data?.lat, data?.lng);
      }
    }
  }, [data?.lat, data?.lng]);

  return (
    <div style={{ width: '100%', height: '390px' }}>
      {/* Ensure the map container has dimensions */}
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default MapComponent;
