import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import './BuilderDetails.css'
import { getAdvertisement } from "../api/api";
import Advertisement from './Advertisement';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiCheckDouble } from "react-icons/bi";
import BuilderProjectSlides from './BuilderProjectSlides';
import logo from '../assets/assets/img/MyPlotPic-2 (1).jpg';
import { IoClose } from "react-icons/io5";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Fade from 'react-reveal/Fade';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";

import '../assets/assets/vendor/animate.css/animate.min.css'
import '../assets/assets/vendor/aos/aos.css'
import '../assets/assets/vendor/bootstrap/css/bootstrap.min.css'
import '../assets/assets/vendor/bootstrap-icons/bootstrap-icons.css'
import '../assets/assets/vendor/boxicons/css/boxicons.min.css'
import '../assets/assets/vendor/glightbox/css/glightbox.min.css'
import '../assets/assets/vendor/remixicon/remixicon.css'
import '../assets/assets/vendor/swiper/swiper-bundle.min.css'
import '../assets/assets/css/style1.css'
import Builder2 from '../page/Builder';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    }
  };

export default function BuilderDetails(props) {
    console.log(props.BuilderDetail,'props.BuilderDetail');
    
    const { companyname, files, message, email,completed_projects, project_progress, priceDetail1, priceDetail2, address} = props.BuilderDetail || {};
    // let Features = Array.from(JSON.parse(props.BuilderDetail?.Features)) || {};
    // let completedProjectsData = Array.from(JSON.parse(props.BuilderDetail.completed_projects)) || {};
    // let projectProgressData = Array.from(JSON.parse(props.BuilderDetail.project_progress)) || {};
    // let Highlights = Array.from(JSON.parse(props.BuilderDetail.Highlights)) || {};
    // let layoutdetails = Array.from(JSON.parse(props.BuilderDetail.layoutdetails)) || {};
    // let photosOfBuilder = Array.from(JSON.parse(props.BuilderDetail.photosOfBuilder)) || {};
    const [state, setState] = useState({});
    const [sideBar, setSideBar] = useState(false);
    const builderSubdomainProps = props.builderSubdomain || null;
    const [builderAds, setbuilderAds] = useState([]);
    const [completed_projectsArr, setcompleted_projectsArr] = useState(completed_projects);
    const [project_progressArr, setproject_progressArr] = useState(project_progress);
    const handleClick = () => {
        setSideBar(s => !s);
    };
    useEffect(() => {
        getAdvertisement().then((builderAdsVal) => {
            const advertData = builderAdsVal.data || [];
            const filteredBuilderAds = _.filter(advertData, function (ad) {
                try {
                    if (typeof ad.builder === 'object' && ad.builder[0] !== undefined) {
                        let ads_datadd = JSON.parse(ad.builder);
                        if (ads_datadd) {
                            let loopSubdomain = ads_datadd[0].subdomain;
                            return loopSubdomain === builderSubdomainProps;
                        }
                    }
                } catch (e) {
                    //console.warn(e)
                }
            });
            setbuilderAds(filteredBuilderAds);
        });
    }, [builderSubdomainProps]);
    const file_path = 'https://backend.myplotpic.com/uploads/';
    const builder_image = (files ? file_path + files[0] : '');
    useEffect(() => {
        if (project_progress !== undefined) {
            setproject_progressArr(project_progress);
        }
        if (completed_projects !== undefined) {
            setcompleted_projectsArr(completed_projects);
        }
        return () => {
            setState({}); // Unmounting to prevent Memory Leak
        }
    }, [completed_projects, project_progress]);
    const [datetime, setTime] = useState(new Date());
  
    return (
        <div>
            {/* <Modal
                show={sideBar}
                backdrop="static"
                keyboard={false}
                dialogClassName="border-radius-1"
                centered
                size="sm"
                fullscreen={true}
                >
                <ModalBody>
                    <IoClose
                        size={30}
                        className="closeIconX"
                        onClick={handleClick}
                    />
                    <div className='sideBar-mobile'>
                        <ul>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#hero">About</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#features">Features</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#highlights">Highlights</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto " href="#portfolio">Layout Plan</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#team">Progress</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#cp">Completed Projects</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#ads">Ads</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#pricing">Price</a>
                            </li>
                            <li onClick={handleClick}>
                                <a className="nav-link scrollto" href="#cta">Contact</a>
                            </li>
                        </ul>
                    </div>
                </ModalBody>
            </Modal>
            <div id="topbar" className="fixed-top d-flex align-items-center " style={{backgroundColor: "#605f5f"}}> 
                <div className="container d-flex align-items-center justify-content-center justify-content-md-between">
                    <div className="contact-info d-flex align-items-center">
                        <img src={logo} style={{height: 50}}/><span style={{color: "white"}}> +91 7249556789</span>
                    </div>
                </div>
            </div>
            <header id="header" className="fixed-top d-flex align-items-center ">
                <div className="container d-flex align-items-center justify-content-between">
                <h1 className="logo">
                    <a href="">
                        <img src={builder_image} style={{ height: "100%", width: "100%" }}  alt='logo' />
                    </a>
                </h1>
                <nav id="navbar" className="navbar">
                    <ul>
                    <li><a className="nav-link scrollto changeColor_nav" href="#hero">About </a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#features">Features</a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#highlights">Highlights</a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#portfolio">Layout Plan</a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#team">Progress</a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#cp">Completed Projects</a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#pricing">Price</a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#ads">Ads</a></li>
                    <li><a className="nav-link scrollto changeColor_nav" href="#cta">Contact</a></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={handleClick}></i>
                </nav>
                </div>
            </header>
            <section  className="d-flex justify-cntent-center align-items-center"></section>
            <section id="hero" className="d-flex justify-content-center builderHero">
                <div id="heroCarousel" data-bs-interval="5000" className="container carousel carousel-fade" data-bs-ride="carousel">
                    <div className="container">
                        <div className="upper-info-box">
                            <div className="animation-fadeInDiv">
                                <div className="row">
                                    <div className="about-property col-lg-8 col-md-12 col-sm-12">
                                        <h2 className="companyName-margin">{companyname}</h2>
                                        <div className="location">
                                            <i className="la la-map-marker">
                                            </i>{address}
                                        </div>
                                        <div className="col-12 pt-5">
                                            <ul className="nav nav-pills text-center" id="pills-tab" role="tablist">
                                                <li className="nav-item m-b-5" role="presentation">
                                                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Description</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Address</button>
                                                </li>
                                            </ul>
                                            <div className="col-md-9" style={{marginTop: -5, marginLeft: -5}}>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade show active  card shadow" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                        <div className="card-body p-5">
                                                            <h5 className="card-title">PROPERTY DESCRIPTION</h5>
                                                                {message}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 text-right">     
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div  className="ratio ratio-16x9">
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3794.8479228514666!2d83.51405311419616!3d17.98581099000522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3bff70bb3285d3%3A0xdddfa4fbd37ce8fe!2sPrakruti%20Red%20Carpet!5e0!3m2!1sen!2sin!4v1655273469464!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="carouselExampleFade" class="mt-4" data-bs-ride="carousel">
                                            <Carousel
                                                autoPlay={false}
                                                autoPlaySpeed={1400}
                                                partialVisible 
                                                itemClass="image-item"
                                                responsive={responsive}
                                            >
                                                {
                                                    photosOfBuilder.map((item, index) => (
                                                        <img key={index} src={"https://backend.myplotpic.com/uploads/"+item.image} ></img>
                                                    ))
                                                }
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="about">
                <div className="builder-features">
                    <p className='progress-txt-builder titleMargin-mobile'>Features</p>
                    <Fade bottom duration={1250}>
                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                {
                                    Features.map((item, index) => (
                                        <div key={index}>
                                            <p className="pricing-span"><BiCheckDouble size={22} className="doubleTick-icon"/>{item.comment}</p>
                                        </div>
                                    ))
                                }
                            </Col>
                            <Col md={6} xs={12} sm={12}>
                                <Carousel
                                    autoPlay={false}
                                    autoPlaySpeed={1400}
                                    partialVisible 
                                    itemClass="image-item"
                                    responsive={responsive}
                                >
                                    {
                                        Features.map((item, index) => (
                                            <img key={index} src={"https://backend.myplotpic.com/uploads/"+item.image} className="builder-img-margins"></img>
                                        ))
                                    }
                                </Carousel>
                            </Col>
                        </Row>
                    </Fade>
                </div>
            </section>
            <section id="highlights" className="services section-bg" >
                <div className="builder-features">
                    <p className='progress-txt-builder-white titleMargin'>Highlights</p>
                    <Fade bottom duration={1250}>
                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <Carousel
                                    autoPlay={false}
                                    autoPlaySpeed={1400}
                                    partialVisible 
                                    itemClass="image-item"
                                    responsive={responsive}
                                >
                                    {
                                        Highlights.map((item, index) => (
                                            <img key={index} src={"https://backend.myplotpic.com/uploads/"+item.image} className="builder-img-margins"></img>
                                        ))
                                    }
                                </Carousel>
                            </Col>
                            <Col md={6} xs={12} sm={12}>
                                {
                                    Highlights.map((item, index) => (
                                        <div key={index}>
                                            <p className="hightlt-txt listItems-margin"><BiCheckDouble size={22} className="doubleTick-icon-white"/>{item.comment}</p>
                                        </div>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Fade>
                </div>
            </section>
            <section id="portfolio" className="services ">
                <div className="builder-features" >
                    <p className='progress-txt-builder'>Layout Plan</p>
                    <Fade bottom duration={1250}>
                        <Row>
                            <Col md={9} xs={12} sm={12}>
                                <div className='layout-shadow'>
                                    <img className="d-block w-100" src={"https://backend.myplotpic.com/uploads/"+layoutdetails[0].image}/>
                                </div>
                                
                            </Col>
                            <Col md={3} xs={12} sm={12}>
                                <center>
                                    <button className="view-pdf-btn"><a href={"https://backend.myplotpic.com/uploads/"+layoutdetails[0].pdf} target="_blank"  download="FileName" style={{color: "#fff"}}> View  PDF </a></button>
                                </center>
                            </Col>
                        </Row>
                    </Fade>
                </div>
            </section>
            {
                projectProgressData?
                    <section id="team" className="services section-bg">
                        <div className="container-fluid">
                            <div className="section-title">
                                <h2 style={{color: "#fff", marginBottom: 35}}>Progress of project</h2>
                            </div>
                            <Fade bottom duration={1250}>
                                <Row>
                                    <Col md={6} xs={12} sm={12}>
                                        {
                                            projectProgressData[0]?.comment ?
                                                <div className="icon-box">
                                                    <i className="bi bi-youtube"></i>
                                                    <h4 style={{color:"#3D1F68"}}><a href="#">Virtual Tour</a></h4>
                                                    <p className="pb-3">Visit plot through your device from your comfortable location! </p>
                                                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9 shadow">
                                                        <iframe className="embed-responsive-item" src={projectProgressData[0]?.comment} allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            :
                                            <></>
                                        }
                                    </Col>
                                    <Col md={6} xs={12} sm={12}>
                                        {
                                            projectProgressData[1]?.comment ?
                                                <div className="icon-box">
                                                    <i className="bi bi-youtube"></i>
                                                    <h4 style={{color:"#3D1F68"}}><a href="#">Youtube</a></h4>
                                                    <p className="pb-3">Watch our youtube video to know more about this current project</p>
                                                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9 shadow">
                                                        <iframe className="embed-responsive-item" src={projectProgressData[1]?.comment} allowfullscreen></iframe>
                                                    </div>                      
                                                </div>
                                            :
                                            <></>
                                        }
                                    </Col>
                                </Row>
                            </Fade>
                        </div>
                    </section>
                :
                    <></>
            }
            <section id="cp" className="team">
                <div className="container">
                    <div className="section-title">
                        <h2 style={{marginBottom: 35}}>Completed Projects</h2>
                    </div>
                    <Fade bottom duration={1250}>
                        <Row>
                            {
                                completedProjectsdata?.map((item, index) => (
                                    <Col md={6} sm={12} xs={12} key={index}>
                                        <div className="member d-flex align-items-stretch completeProjects-mobile">
                                            <div className="pic">
                                                <img src={"https://backend.myplotpic.com/uploads/"+item.image} className="img-fluid" alt="" style={{width:"99%", height: 140, borderRadius: 4}}/>
                                                <h4 style={{marginTop:"9%", color:"#3D1F68"}}>APRERA APPROVED</h4>
                                            </div>
                                            <div>
                                                <iframe className="embed-responsive-item" src={item.comment} allowFullScreen width="95%" height="210" style={{borderRadius:7, marginLeft:"5%"}}></iframe>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Fade>
                </div>
            </section>
            <section id="pricing" className="pricing">
                <div className="container">
                    <p className='progress-txt-builder'>Pricing</p>
                    <Fade bottom duration={1250}>
                        <div className="row justify-content-md-center">
                            <div className="col-lg-3 col-md-6">
                                <div className="box featured">
                                    <h3>Price Details</h3>
                                    <p>Rs. {priceDetail1}/square yard</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
                                <div className="box featured">
                                    <h3>Current Market Details</h3>
                                    <p>Rs. {priceDetail2}/square yard</p>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <section id="ads">
                <div className='ads-info'>
                    <p className='progress-txt-builder-white ads-margin-less'>Advertisements</p>
                    <Fade bottom duration={1250}>
                        <Advertisement builderAds={builderAds} />
                    </Fade>
                </div>
            </section>
            <section id="cta" className="cta">
                <div className="container">
                    <p className='progress-txt-builder-white'>Call to action</p>
                    <Fade bottom duration={1250}>
                        <div className="row">
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect/name="+companyname}>Buy a Plot</a>
                            </div>
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect/name="+companyname}>Order Virtual tour</a>
                            </div>
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect/name="+companyname}>Order Physical visit</a>
                            </div>
                            <div className="col-lg-3 cta-btn-container text-center">
                                <a className="cta-btn align-middle" target="_blank" href={"http://plotpic.crix9.com/connect/name="+companyname}>Resale a plot</a>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section> */}


            <Builder2 {...props.BuilderDetail}/>
        </div>
    )
}
