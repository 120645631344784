import React from 'react'

const Footer = ({data}) => {
  return (
    <div>
        <footer className="w-100  text-white px-2 py-3" style={{
            background: 'rgba(0, 71, 107, 1)'

        }}>
  <div className="mx-4 d-flex flex-column flex-sm-row justify-content-between align-items-center ">
    <div className="d-flex align-items-center gap-2">
      <span className="fw-medium">Developer :</span>
      <span>{data?.name}</span>
    </div>
    <div className="d-flex align-items-center gap-2">
      <span className="fw-medium">email :</span>
      <span>{data?.email}</span>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer