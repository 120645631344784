import React, { useState, useEffect } from 'react'
import _ from 'lodash';

export default function ProjectDetails({ adInfo }) {
    const [Adinfo, setAdinfo] = useState(adInfo);
    useEffect(() => {
        if (typeof adInfo !== 'object') {
            setAdinfo(JSON.parse(adInfo) || []);
        }
    }, [adInfo]);
    return (
        <div className="__ProjectDetails">
            <h3>Building Details</h3>
            {
                Adinfo && _.map(Adinfo, (ad, index) => {
                    //noofflats, floor, facing, area
                    return (
                        <div key={index} className="__ProjectDetailsitems d-flex gap-2 flex-wrap mb-2">
                            <div className="flex-column justify-content-center align-items-center">
                                <h4>Total Floors</h4>
                                <span className="details-values">{ad.totalfloors}</span>
                            </div>
                            <div className="flex-column justify-content-center align-items-center">
                                <h4>Total Flats</h4>
                                <span className="details-values">{ad.noofflats}</span>
                            </div>
                            <div className="flex-column justify-content-center align-items-center">
                                <h4>Floor</h4>
                                <span className="details-values">{ad.floor}</span>
                            </div>
                            <div className="flex-column justify-content-center align-items-center">
                                <h4>Facing</h4>
                                <span className="details-values">{ad.facing}</span>
                            </div>
                            <div className="flex-column justify-content-center align-items-center">
                                <h4>Area</h4>
                                <span className="details-values">{ad.area}</span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
