 import React from "react";

 import SBI from '../../assets/sbi.png'
 import HDFC from '../../assets/HDFC-Bank-logo 1.png'
 import Icici from '../../assets/icici.png'
 import './style.css'
export default function BankLoans({data}) {
  const banks = [
    {
      name: "HDFC Bank",
      logo:HDFC,
    },
    {
      name: "ICICI Bank",
      logo: Icici,
    },
    {
      name: "SBI",
      logo:SBI,
    },
  ];

  return (
    <div className="w-full .min-vh-100s bg-gradient-to-r from-yellow-50 via-green-50 to-blue-50 py-12">
      <div className="container px-4">
        <h1 className="text-3xl font-weight-bold bank-heading text-center mb-5">Bank Loans</h1>
        <div className="row justify-content-center">
          {data?.bankLoanSchema.map((bank) => (
            <div key={bank.name} className="col-12  col-md-4 d-flex justify-content-center mb-4">
              <div className="card   bank-card  rounded-top p-4">
                <div className="d-flex flex-column justify-content-evenly align-items-center gap-3" style={{
                    height:'100%',
                     
                }}>
                  {/* Bank Logo */}
                  <div className="d-flex justify-content-center h-12">
                    <img
                     src={`https://backend.myplotpic.com/${bank?.logo}`}
                       
                      alt={`${bank?.name} logo`}
                      width={200}
                      height={50}
                      className="max-w-[200px] h-auto"
                    />
                  </div>
                  {/* Apply Now Button */}
                 <a href={`${bank?.applyLink}`} target="_blank">
                   <button className=" apply-btn btn btn-light w-100 text-dark">
                    Apply Now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
