import React from 'react'
import './Slider.css'
import img from '../../assets/industrial-Plots-Yamuna 1.png'
import MapComponent from '../map/Map'
const Silder = ({data}) => {
  return (
    <div className='slider_main_container'>
        <div className='slider_container'> 
<div className="slider_div">

<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    {data?.project_progress.map((i, key) => (
      <button
        key={key}
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to={key}
        className={key === 0 ? 'active' : ''}
        aria-label={`Slide ${key + 1}`}
      ></button>
    ))}
  </div>

  <div class="carousel-inner">
    {data?.project_progress.map((i, key) => (
      <div
        key={key}
        className={`carousel-item ${key === 0 ? 'active' : ''}`}
      >
        <img
          src={`https://backend.myplotpic.com/${i.image}`}
          className="d-block w-100 h-100"
          alt="..."
        />
      </div>
    ))}
  </div>

  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

</div>

<div className="map_main_div">
   
   <div className='map_div'>
  < MapComponent data={data}/>

  <div className='detail_location'>
    
        <p>
            <span>Address:</span> 2303, Tota Ram Bazar, Near Canara Bank, Tri Nagar
        </p>
        <p>
            <span>State: </span>
             Andhra Pradesh
        </p>
        <p>
            <span>Pincode:</span>
            530026
        </p>
        <p>
            <span>Country:</span>
              India
        </p>
   
 
  </div>
   </div>
</div>
        </div>

    </div>
  )
}

export default Silder