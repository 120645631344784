import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
 
import AdvertisementDetail from './components/AdvertisementDetail'
import 'bootstrap/dist/css/bootstrap.min.css';
 import './App.css'
import BuilderDetails from './components/BuilderDetails';
import Builder from './components/Builder';
 
 

export default function App() {
	return (

		// <div>
			// <Builder2/>
		// </div>
		<Router basename="/">
		<Switch>
			<Route exact path="/" component={Builder} />
			<Route path="/advertisement/:adId" component={AdvertisementDetail} />
		</Switch>
	</Router>
	);
}
