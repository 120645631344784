import React from 'react'
import share from '../../assets/Icon/share.svg'
import './Header.css'
const Header = ({data}) => {
  return (
    <div className='header_section'>
        
        <div className='left_side'>
            
            <div className='main_div'>
                <h1>{data?.name ||'East Heaven Raise, Visakhapatnam'}</h1>
                <p>{data?.address ||'Old Gajuwaka, Visakhapatnam, Andhra Pradesh, India, 530026'}</p>
            </div>
        </div>
        <div className='right_side'>
            
            <div>
                <img src={share} alt="share.png" />
            </div>
        </div> 
        
        </div>
  )
}

export default Header