import React from 'react'
import Header from './components/Header/Header'
import Silder from './components/Silder/Silder'
import Layout_Section from './components/Layout_Section/Layout_Section'
import ProjectsFeatures from './components/Projects Features/ProjectsFeatures'
  
import Amenities from './components/Amenities/Amenities.jsx'
import PropertyDocuments from './components/PropertyDocuments/PropertyDocuments.jsx'
import BankLoans from './components/BankLoans/BankLoans.jsx'
import BookingPlot from './components/BookingPlot/BookingPlot.jsx'
import VideoGallery from './components/VideoGallery/VideoGallery.jsx'
import Footer from './components/footer/Footer.jsx'
 
const Builder2 = ({
email,name,phone,address,layoutImage,project_progress,AmenitySchema,Features,Highlights,documentSchema,approvalSchema,bankLoanSchema,
ytlink,lat,lng,priceDetail1,priceDetail2
}) => {


  console.log(Features);
  
  return (
    <div>
      
      <Header data={{name,address}}/>
      <Silder data={{project_progress,lat,lng}}/>
      <Layout_Section data={{layoutImage,Highlights}}/>
      <ProjectsFeatures data={{Features}}/>
      <Amenities data={{AmenitySchema}}/>
      <PropertyDocuments data={{documentSchema,approvalSchema,priceDetail1,priceDetail2}}/>
      <BankLoans data={{bankLoanSchema}}/>
      <BookingPlot data={
     { email,phone}
      }/>
      <VideoGallery data={{ytlink}}/>
    <Footer data={{name,email}}/>
    </div>
  )
}

export default Builder2